// import "../../Styles/Mainbody.css";
import "../../Styles/Example.css";
import Example from "../../Assets/Example.svg";

const GuideModal = () => {
  return (
    <div className="guideModal">
      <div className="createWall">
        <svg
          width="200"
          height="200"
          viewBox="0 0 200 200"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Frame 1" clipPath="url(#clip0_2_2)">
            <g id="OtherPolygon">
              <path
                id="Polygon 2"
                d="M78 113L99.6506 125.5V150.5L78 163L56.3494 150.5V125.5L78 113Z"
                fill="#C4C4C4"
              />
              <path
                id="Polygon 4"
                d="M144 75L165.651 87.5V112.5L144 125L122.349 112.5V87.5L144 75Z"
                fill="#C4C4C4"
              />
              <path
                id="Polygon 6"
                d="M78 37L99.6506 49.5V74.5L78 87L56.3494 74.5V49.5L78 37Z"
                fill="#C4C4C4"
              />
              <path
                id="Polygon 9"
                d="M34 37L55.6506 49.5V74.5L34 87L12.3494 74.5V49.5L34 37Z"
                fill="#C4C4C4"
              />
              <path
                id="Polygon 10"
                d="M56 -1L77.6506 11.5V36.5L56 49L34.3494 36.5V11.5L56 -1Z"
                fill="#C4C4C4"
              />
              <path
                id="Polygon 11"
                d="M34 113L55.6506 125.5V150.5L34 163L12.3494 150.5V125.5L34 113Z"
                fill="#C4C4C4"
              />
              <path
                id="Polygon 12"
                d="M166 113L187.651 125.5V150.5L166 163L144.349 150.5V125.5L166 113Z"
                fill="#C4C4C4"
              />
              <path
                id="Polygon 13"
                d="M166 37L187.651 49.5V74.5L166 87L144.349 74.5V49.5L166 37Z"
                fill="#C4C4C4"
              />
              <path
                id="Polygon 14"
                d="M144 -1L165.651 11.5V36.5L144 49L122.349 36.5V11.5L144 -1Z"
                fill="#C4C4C4"
              />
              <path
                id="Polygon 15"
                d="M56 151L77.6506 163.5V188.5L56 201L34.3494 188.5V163.5L56 151Z"
                fill="#C4C4C4"
              />
              <path
                id="Polygon 16"
                d="M100 151L121.651 163.5V188.5L100 201L78.3494 188.5V163.5L100 151Z"
                fill="#C4C4C4"
              />
              <path
                id="Polygon 17"
                d="M144 151L165.651 163.5V188.5L144 201L122.349 188.5V163.5L144 151Z"
                fill="#C4C4C4"
              />
              <path
                id="Polygon 7"
                d="M56 75L77.6506 87.5V112.5L56 125L34.3494 112.5V87.5L56 75Z"
                fill="#C4C4C4"
              />
            </g>
            <path
              id="Animate4"
              d="M122 113L143.651 125.5V150.5L122 163L100.349 150.5V125.5L122 113Z"
              fill="#C4C4C4"
            />
            <path
              id="Animate3"
              d="M100 75L121.651 87.5V112.5L100 125L78.3494 112.5V87.5L100 75Z"
              fill="#C4C4C4"
            />
            <path
              id="Animate2"
              d="M122 37L143.651 49.5V74.5L122 87L100.349 74.5V49.5L122 37Z"
              fill="#C4C4C4"
            />
            <path
              id="Animate1"
              d="M100 -1L121.651 11.5V36.5L100 49L78.3494 36.5V11.5L100 -1Z"
              fill="#C4C4C4"
            />
            <g id="Cursor" filter="url(#filter0_d_2_2)">
              <path
                d="M177.054 5.93685L198.886 20.0035L183.964 17.9048L177.549 32.3225L177.054 5.93685Z"
                fill="#0F0C0C"
              />
            </g>
          </g>
          <defs>
            <filter
              id="filter0_d_2_2"
              x="173.054"
              y="5.93686"
              width="29.832"
              height="34.3856"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_2_2"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_2_2"
                result="shape"
              />
            </filter>
            <clipPath id="clip0_2_2">
              <rect width="200" height="200" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
      <div className="moveOrigin">
        <svg
          width="200"
          height="200"
          viewBox="0 0 200 200"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Frame 1" clipPath="url(#clip0_2_2)">
            <g id="OtherPolygon">
              <path
                id="Polygon 2"
                d="M78 113L99.6506 125.5V150.5L78 163L56.3494 150.5V125.5L78 113Z"
                fill="#C4C4C4"
              />
              <path
                id="Polygon 4"
                d="M144 75L165.651 87.5V112.5L144 125L122.349 112.5V87.5L144 75Z"
                fill="#C4C4C4"
              />
              <path
                id="Polygon 6"
                d="M78 37L99.6506 49.5V74.5L78 87L56.3494 74.5V49.5L78 37Z"
                fill="#C4C4C4"
              />
              <path
                id="Polygon 9"
                d="M34 37L55.6506 49.5V74.5L34 87L12.3494 74.5V49.5L34 37Z"
                fill="#C4C4C4"
              />
              <path
                id="Polygon 10"
                d="M56 -1L77.6506 11.5V36.5L56 49L34.3494 36.5V11.5L56 -1Z"
                fill="#C4C4C4"
              />
              <path
                id="Polygon 11"
                d="M34 113L55.6506 125.5V150.5L34 163L12.3494 150.5V125.5L34 113Z"
                fill="#C4C4C4"
              />
              <path
                id="Polygon 12"
                d="M166 113L187.651 125.5V150.5L166 163L144.349 150.5V125.5L166 113Z"
                fill="#C4C4C4"
              />
              <path
                id="Polygon 13"
                d="M166 37L187.651 49.5V74.5L166 87L144.349 74.5V49.5L166 37Z"
                fill="#C4C4C4"
              />
              <path
                id="Polygon 14"
                d="M144 -1L165.651 11.5V36.5L144 49L122.349 36.5V11.5L144 -1Z"
                fill="#C4C4C4"
              />
              <path
                id="Polygon 15"
                d="M56 151L77.6506 163.5V188.5L56 201L34.3494 188.5V163.5L56 151Z"
                fill="#C4C4C4"
              />
              <path
                id="Polygon 16"
                d="M100 151L121.651 163.5V188.5L100 201L78.3494 188.5V163.5L100 151Z"
                fill="#C4C4C4"
              />
              <path
                id="Polygon 17"
                d="M144 151L165.651 163.5V188.5L144 201L122.349 188.5V163.5L144 151Z"
                fill="#C4C4C4"
              />
              <path
                id="Polygon 7"
                d="M56 75L77.6506 87.5V112.5L56 125L34.3494 112.5V87.5L56 75Z"
                fill="#C4C4C4"
              />
            </g>
            <path
              id="Animate4"
              d="M122 113L143.651 125.5V150.5L122 163L100.349 150.5V125.5L122 113Z"
              fill="#C4C4C4"
            />
            <path
              id="Animate3"
              d="M100 75L121.651 87.5V112.5L100 125L78.3494 112.5V87.5L100 75Z"
              fill="#C4C4C4"
            />
            <path
              id="Animate2"
              d="M122 37L143.651 49.5V74.5L122 87L100.349 74.5V49.5L122 37Z"
              fill="#C4C4C4"
            />
            <path
              id="Animate1"
              d="M100 -1L121.651 11.5V36.5L100 49L78.3494 36.5V11.5L100 -1Z"
              fill="#C4C4C4"
            />
            <g id="Cursor" filter="url(#filter0_d_2_2)">
              <path
                d="M177.054 5.93685L198.886 20.0035L183.964 17.9048L177.549 32.3225L177.054 5.93685Z"
                fill="#0F0C0C"
              />
            </g>
          </g>
          <defs>
            <filter
              id="filter0_d_2_2"
              x="173.054"
              y="5.93686"
              width="29.832"
              height="34.3856"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_2_2"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_2_2"
                result="shape"
              />
            </filter>
            <clipPath id="clip0_2_2">
              <rect width="200" height="200" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
    </div>
  );
};

export default GuideModal;
